.contact-form-wrapper {
  border-top: 1px solid var(--border-primary);
  width: 100%;
  margin-left: var(--flex-padding);
}

.title {
  padding-top: var(--paragraph-padding);
}

.title h3 {
  margin: 0;
}

.contact-form {
  display: flex;
  flex-direction: column;
  padding-top: var(--flex-padding);
}

input,
textarea {
  width: 100%;
  border: 1px solid var(--border-primary);
  border-radius: 0.5rem;
  padding: 1.25rem;
  font-size: var(--step-0);
  font-weight: 600;
  resize: none;
  margin-bottom: calc(var(--flex-padding) * 0.33);
}

label {
  padding-block: var(--paragraph-padding);
}

span {
  font-size: var(--step-0);
}

input:focus,
textarea:focus {
  outline: 2px solid rgb(204, 219, 218);
  background-color: rgb(240, 240, 240);
}

::placeholder {
  font-weight: 400;
}