#navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  transition: background-color 0.3s ease;
  color: black;
  z-index: 1000;
}

#navbar.transparent {
  background-color: transparent;
}

.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 2rem;
  height: 5rem
}

.navbar h2 {
  font-size: var(--step-2);
  font-family: "warnock", serif;
}

.navbar .nav-links {
  position: relative;
}

.navbar .nav-links ul {
  display: flex;
  list-style: none;
}

.navbar .nav-links ul li {
  padding-left: 1rem;
}

.navbar .nav-links ul li:last-child {
  padding-left: 0;
}

.underline {
  position: absolute;
  bottom: -2px;
  height: 2px;
  /* Initially no width */
  background-color: var(--color-light-grey);
  transition: width 0.3s ease, left 0.3s ease;
  /* Smooth transitions */
}

.hamburger {
  display: none;
  cursor: pointer;
  z-index: 1;
}

.hamburger .bar {
  display: block;
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 5px 0;
  transition: 0.4s;
}

/* Full-screen drawer styles */
.nav-links {
  transition: transform 0.3s ease;
}

.nav-links.open {
  transform: translateX(0);
}

.hamburger .bar.white {
  display: block;
  width: 25px;
  height: 3px;
  background-color: var(--color-light);
  margin: 5px 0;
  transition: 0.4s;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .hamburger {
    display: block;
    /* Show hamburger icon on smaller screens */
  }

  .navbar .nav-links {
    position: absolute;
    width: 66vw;
    height: 100vh;
    top: 0;
    right: 0;
    background-color: var(--color-grey);
    transform: translateX(100%);
    /* Start off-screen */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* Stack the links vertically */
  }

  .nav-links.open {
    transform: translateX(0);
    /* Slide in when open */
  }

  .nav-links ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* Stack the links vertically */
  }

  .nav-links ul li {
    padding: 15px 0;
    color: var(--color-light);
  }
}