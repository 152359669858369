/* ===================== Style by Sethephur =================== */

/* -------------------------- Fonts --------------------------- */
@font-face {
  font-family: 'buttershine';
  src: url("../../public/fonts/BUTTERSHINE-SERIF.otf");
}

@font-face {
  font-family: 'noto';
  src: url("../../public/fonts/Noto_Sans/NotoSans-VariableFont_wdth,wght.ttf");
}

@font-face {
  font-family: 'warnock';
  src: url("../../public/fonts/warnock-pro/WarnockPro-LightSubh.otf");
}

@font-face {
  font-family: 'canto-brush';
  src: url('../../public/fonts/CantoBrushOpen-Roman.woff2');
}

/* -------------------------- Variables -------------------------- */
:root {
  /* Colors */
  --color-dark: #141414;
  --color-grey: #252525;
  --color-light-grey: #464646;
  --color-light: #fffcf0;
  --color-white: #fff;

  /* Font size */
  --step--2: clamp(0.7813rem, 0.7747rem + 0.0326vw, 0.8rem);
  --step--1: clamp(0.9375rem, 0.9158rem + 0.1087vw, 1rem);
  --step-0: clamp(1.125rem, 1.0815rem + 0.2174vw, 1.25rem);
  --step-1: clamp(1.35rem, 1.2761rem + 0.3696vw, 1.5625rem);
  --step-2: clamp(1.62rem, 1.5041rem + 0.5793vw, 1.9531rem);
  --step-3: clamp(1.9438rem, 1.7707rem + 0.8652vw, 2.4413rem);
  --step-4: clamp(2.3325rem, 2.0823rem + 1.2511vw, 3.0519rem);
  --step-5: clamp(2.7994rem, 2.4461rem + 1.7663vw, 3.815rem);
  --step-6: clamp(6.45rem, 5.4541rem + 4.9793vw, 9.3131rem);

  /* Spacing */
  --top-padding: 12rem;
  --flex-padding: 3rem;
  --section-padding: 8rem;
  --paragraph-padding: 1em;

  /* Borders */
  --border-primary: #000;
  --border-secondary: #b5b5b5;
  --border-light: #dddddd;

  /* Animations */
  --animation-primary: all .5s cubic-bezier(.7, 0, .3, 1);
  --animation-fast: all .3s cubic-bezier(.7, 0, .3, 1);
  --animation-smooth: all .7s cubic-bezier(.7, 0, .3, 1);
  --animation-slow: all .9s cubic-bezier(.7, 0, .3, 1);

  /* Rating */
  --color-background-rating-container-hover: #ebebeb;
  --color-background-rating-container-selected-base: #cfccc9;
  --color-background-rating-container-selected-hover: #c2c7cc;
  --color-background-rating-empty-base: #ebebeb;
  --color-background-rating-empty-hover: #cfccc9;
  --color-background-rating-empty-selected-hover: #f2f2f2;
  --color-background-rating-fill-base: #e87400;
  --color-background-rating-star-base: #e87400;
  --color-background-rating-star-empty: #c2c7cc;
  --color-background-rating-star-border: #8e8e97;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }

  50% {
    transform: translateY(-10px);
    animation-timing-function: ease-in;
  }
}

/* ------------------------- Global Styles ------------------------- */
*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'noto', sans-serif;
  color: var(--color-dark);
  line-height: 1.6;
  font-weight: 400;
  font-style: normal;
  overflow-x: hidden;
}

li,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

strong {
  font-weight: 600;
}

img,
video {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  box-sizing: border-box;
}

svg {
  max-width: none;
  height: auto;
  vertical-align: middle;
  box-sizing: border-box;
}

.icon {
  width: 30px;
}

audio,
canvas,
iframe {
  vertical-align: middle;
}

::selection {
  background-color: var(--color-grey);
  color: var(--color-white);
  text-shadow: none;
}

::-moz-selection {
  background-color: var(--color-grey);
  color: var(--color-white);
  text-shadow: none;
}

/* ------------------------- Typography ------------------------- */
.serif {
  font-family: 'warnock';
}

.sans-serif {
  font-family: 'noto';
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'warnock', 'Times New Roman', Times, serif;
  font-weight: 500;
}

h1 {
  font-size: var(--step-6);
}

h2 {
  font-size: var(--step-5);
  font-weight: 500;
}

h3 {
  font-size: var(--step-4);
  font-weight: 500;
}

h4 {
  font-size: var(--step-3);
}

h5 {
  font-size: var(--step-2);
}

h6 {
  font-size: var(--step-1);
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  font-size: var(--step-0);
}

/* ------------------------- Layout & Grids ------------------------- */
.container-large {
  width: clamp(0px, 98vw, 1660px);
  margin: auto;
}

.container-medium-large {
  width: clamp(0px, 86vw, 1660px);
  margin: auto;
}

.container-medium {
  width: clamp(0px, 75vw, 1440px);
  margin: auto;
}

.container-small {
  width: clamp(0px, 60vw, 1440px);
  margin: auto;
}

@media (max-width: 756px) {

  .container-large,
  .container-medium-large,
  .container-medium,
  .container-small {
    width: 96%;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.bottom-padding {
  width: 100%;
  height: 1px;
}

/* ------------------------- Components ------------------------- */
.btn {
  width: max-content;
  border: 2px solid var(--border-primary);
  border-radius: 0.5rem;
  background-color: white;
  padding: 0.5rem 1rem;
  font-size: var(--step-1);
  margin-top: var(--flex-padding);
  margin-right: var(--paragraph-padding);
  transition: var(--animation-primary);
}

.btn:hover {
  background-color: black;
  color: white;
}

.btn-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'noto', sans-serif;
  width: 100%;
  height: 100%;
  padding: 0 2.5em;
  z-index: 2;
  position: relative;
  pointer-events: none;
}

.hero-text h1 {
  font-weight: 500;
}

.title {
  font-weight: 500;
}

/* Writing Page Hero */
.writing-hero-background {
  background-color: white;
  height: 100vh;
  display: flex;
  align-items: center;
}

.writing-hero-background .hero-content {
  align-items: center;
}

.writing-hero-background .hero-content .hero-text {
  padding-right: var(--flex-padding);
}

.writing-hero-background .hero-content .hero-img {
  position: relative;
  right: -2rem;
  padding-left: var(--flex-padding);
}

.writing-hero-background .hero-content .hero-img img {
  max-width: none;
  min-width: 100%;
  max-height: 100vh;
}


.writing-hero-background h1 {
  color: black;
}

@media (max-width: 460px) {
  .writing-hero-background .hero-content.row {
    flex-direction: column;
    margin: 0;
    overflow-x: hidden;
  }

  .writing-hero-background .hero-content .hero-img {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
  }
}


/* Home Page */
.home {
  width: 100%;
  height: 100%;
}

.home-hero {
  background-image: url(../../public/images/bookshelf.jpg);
  width: 100%;
  height: 100vh;
}

.home-hero-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  color: var(--color-white);
  -webkit-backdrop-filter: brightness(0.7);
  backdrop-filter: brightness(0.7);
}

.hero-title {
  position: absolute;
  top: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  overflow-x: hidden;
}

.hero-title h1 {
  font-size: calc(var(--step-6) * 1.5);
  white-space: nowrap;
  line-height: 0;
  font-style: italic;
}

.hero-btns {
  position: absolute;
  bottom: 0;
  left: 50%;
  color: var(--color-dark);
}


.footer {
  position: relative;
  display: flex;
  justify-content: center;
  color: white;
  border: 1px solid transparent;
  border-radius: 1rem;
  background-color: var(--color-dark);
  padding-block: 4rem;
  margin-block: calc(100vw * 0.02 - 10px);
  margin-inline: auto;
}

.footer .row .flex-col {
  width: 100%;
  justify-content: center;
}

.footer .row .flex-col p {
  margin: 0.5rem;
}

.footer .row .flex-col:nth-child(1) {
  border-right: 1px solid white;
  margin-right: 10%;
}

.footer .row .flex-col:nth-child(1) img {
  width: 60%;
}

.footer .back-to-top {
  position: absolute;
  cursor: pointer;
  top: 2rem;
  right: 2rem;
  background-color: white;
  color: black;
  padding: 0.4rem 0.75rem;
  border-radius: 0.75rem;
  font-size: 1.5rem;
  height: fit-content;
}


/* About Page */
.about-page {
  position: relative;
}

.about-sky {
  position: relative;
  border-top: 1px solid var(--border-primary);
  margin-top: var(--section-padding);
  margin-bottom: var(--section-padding);
}

.about-sky .title {
  padding-top: var(--paragraph-padding);
  padding-bottom: var(--flex-padding);
}

.about-sky .title h2 {
  margin: 0;
}

.about-sky-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  padding-top: var(--paragraph-padding);
}

.portrait {
  grid-column: 1 / 2;
  padding-right: var(--flex-padding);
  display: block;
}

.bio {
  display: flex;
  flex-direction: column;
  grid-column: 2 / 4;
  margin-top: -0.5rem;
}

.bio p {
  font-size: var(--step-1);
}


/* Writing Page */
.writing-page {
  height: 100%;
  position: relative;
}

.writing-page .writing-title {
  border-top: 1px solid var(--border-primary);
  padding-bottom: var(--section-padding);
  padding-top: var(--paragraph-padding);
  margin-top: var(--section-padding);
}

.writing-title .flex-col:nth-child(1) {
  width: 50%;
  padding-right: var(--flex-padding);
}

.writing-title .flex-col:nth-child(2) {
  width: 50%;
  padding-left: var(--flex-padding);
  padding-top: var(--paragraph-padding);
  font-weight: 400;
}

.book-wrapper {
  position: relative;
  margin-block: 2rem;
  padding-top: var(--flex-padding);
  border-top: 1px solid var(--border-secondary);
}

.book-inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
}

/* .book-inner::before {
  position: absolute;
  top: -1.5em;
  content: '01';
  font-family: 'warnock', serif;
  font-size: var(--step-1);
  font-weight: 600;
  color: var(--color-grey);
} */

.book-inner .grid-col:nth-child(1) {
  grid-column: 1 / 3;
  padding-right: var(--flex-padding);
  text-align: left;
}

.book-inner .grid-col:nth-child(2) {
  grid-column: 3 / 4;
}

.book {
  padding-left: var(--flex-padding);
  transition: all 0.3s ease;
  width: 50%;
  margin-inline: auto;
  transform-origin: left;
}

.book:hover {
  filter: brightness(0.75);
  scale: 1.01;
  transform: rotateZ(-0.5deg);
}

.book-inner .book-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.book-inner .book-text span {
  font-size: var(--step-0);
  line-height: 0;
  letter-spacing: 0.25rem;
}

.book-inner .book-text .learn-more-btn {
  width: max-content;
  border: 2px solid var(--border-primary);
  border-radius: 0.5rem;
  background-color: white;
  padding: 1rem 2rem;
  font-size: var(--step-1);
  margin-top: var(--flex-padding);
  transition: all 0.2s;
}

.book-inner .book-text .learn-more-btn:hover {
  background-color: var(--border-secondary);
  color: white;
}

/* Contact Page */
.contact-page-wrapper {
  padding-top: 10rem;
  margin-bottom: var(--section-padding);
}


/* TYS Book Page */
.tys-book-page-wrapper {
  z-index: 2;
}

.tys-book-page-wrapper .row .flex-col:nth-child(1) {
  width: 30%;
}

.tys-book-page-wrapper .row .flex-col:nth-child(2) {
  width: 70%;
}

.tys-book-page-wrapper .title h2 {
  font-family: 'canto-brush', serif;
  font-weight: 100;
  color: var(--color-dark);
  padding-top: 0;
  border-top: 2px solid var(--color-dark);
  padding-bottom: 4rem;
}

.tys-hero {
  position: relative;
  overflow: hidden;
  -webkit-filter: saturate(1.25);
  filter: saturate(1.25);
}

.scroll-container {
  position: absolute;
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
}

.tys-hero .scroll {
  position: absolute;
  bottom: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: bounce 1.5s infinite;
  transition: var(--animation-fast);
}

.scroll:hover {
  scale: 1.1;
}

.scroll:active {
  scale: 0.9;
}

.tys-hero .scroll p {

  font-family: 'warnock';
  font-weight: 600;
  color: #cdb9a4;
  margin: 0;
}

.tys-hero .scroll img {
  width: 0.75em;
}

.parallax {
  width: 100%;
  position: relative;
  left: 0;
  top: -2rem;
  margin-bottom: -12rem;
  display: flex;
  justify-content: center;
  height: 150vh;
  z-index: -1;
  background-color: black;
}

.parallax::after {
  position: absolute;
  width: 100%;
  height: 12rem;
  bottom: -1rem;
  margin-bottom: -1rem;
  left: 0;
  content: '';
  background-color: var(--color-dark);
  z-index: 20000;
}


.parallax-img {
  max-width: none;
  min-width: 100%;
  max-height: 150vh;
}

.parallax-text {
  margin-top: 4rem;
}

.parallax-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
}

@media (max-width: 1710px) {
  .parallax-img {
    width: 1710px;
  }
}

.parallax-text h1 {
  font-family: 'canto-brush';
  top: 0;
  width: 100%;
  text-align: center;
  color: black;
  font-size: clamp(3.732rem, 2.244rem + 7.441vi, 8.011rem);
  font-weight: 500;
}

.parallax-text span {
  font-size: 7rem;
  font-size: clamp(3.11rem, 1.963rem + 5.736vi, 6.409rem);
}

.grid {
  display: grid;
  gap: calc(100vw * 0.02 - 4px);
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.quotes {
  padding-bottom: var(--section-padding);
}

.quotes .grid-item {
  position: relative;
  padding-top: 100%;
  width: 100%;
  overflow: hidden;
  border: 2px solid #f4f2f0;
  border-radius: 1rem;
  padding: 3rem;
}

.big-quote {
  position: relative;
  top: 0.5em;
  font-size: var(--step-6);
  padding-right: 0.25em;
  line-height: 0;
}

.quote {
  font-family: 'warnock';
  font-weight: 500;
  color: var(--color-dark);
  font-size: var(--step-2);
  line-height: 1.5em;
}

.quote-title {
  font-size: var(--step-1);
  margin-top: 2em;
  margin-bottom: 0;
}

.quote-title p {
  margin-top: 0;
}

.grid.quotes .grid-item:nth-child(1) {
  grid-column: 1 / 4;
  padding: 0;
}

.grid.quotes .grid-item:nth-child(1) div,
.grid.quotes .grid-item:nth-child(4) div {
  background-image: url('../../public/images/tys-art-2.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  filter: saturate(0.5);
}

.grid.quotes .grid-item:nth-child(2) {
  grid-column: 4 / 6;
  /* background-image: linear-gradient(-45deg, rgb(190, 207, 193), rgb(224, 188, 175)); */
}

.grid.quotes .grid-item:nth-child(3) {
  grid-column: 1 / 3;
}

.grid.quotes .grid-item:nth-child(4) {
  grid-column: 3 / 6;
  padding: 0;
}

.grid.quotes .grid-item:nth-child(4) div {
  background-image: url('../../public/images/tys-art-3.png');
}

@media (max-width: 1156px) {
  .grid.quotes {
    grid-template-rows: repeat(4, 1fr);
  }

  .grid.quotes .grid-item:nth-child(1) {
    grid-column: 1 / 6;
    grid-row: 2 / 3;
  }

  .grid.quotes .grid-item:nth-child(2) {
    grid-column: 1 / 6;
    grid-row: 1 / 2;
  }

  .grid.quotes .grid-item:nth-child(3) {
    grid-column: 1 / 6;
    grid-row: 3 / 4;
  }

  .grid.quotes .grid-item:nth-child(4) {
    grid-column: 1 / 6;
    grid-row: 4 / 5;
  }

  .footer-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1fr);
  }

  .get-a-copy .gac-grid .book-info {
    grid-column: 1 / 7;
    grid-row: 2 / 3;
  }

  .get-a-copy .gac-grid .book-cover {
    grid-column: 2 / 6;
    grid-row: 1 / 2;
  }
}

@media (max-width: 768px) {

  .grid.quotes .grid-item:nth-child(2),
  .grid.quotes .grid-item:nth-child(3) {
    padding: 1.5rem;
  }

  .about-sky-grid {
    grid-template-columns: 1fr;
  }

  .about-sky-grid .portrait {
    padding-right: 0;
  }

  .about-sky-grid .bio {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .get-a-copy .gac-grid .book-cover {
    grid-column: 1 / 7;
    padding: 0;
  }

  .get-a-copy .gac-grid .book-info {
    grid-column: 1 / 8;
    padding: 0;
  }

  .get-a-copy .gac-grid .book-info .book-mini-sum {
    padding-right: 0;
  }

  .get-a-copy .gac-grid {
    padding-left: 0;
    padding-right: 0;
  }

  .row {
    flex-direction: column;
  }

  .writing-title .flex-col:nth-child(1) {
    padding: 0;
    width: 100%;
  }

  .writing-title .flex-col:nth-child(2) {
    padding: 0;
    width: 100%;
  }

  .book-inner .grid-col:nth-child(1) {
    width: 100%;
    padding: 0;
    grid-column: 1 / 4;
  }

  .footer .row .flex-col:nth-child(1) {
    border-right: none;
    border-bottom: 1px solid white;
    margin-right: 0;
  }

  .footer .back-to-top {
    top: initial;
    bottom: 2rem;
  }
}

@media (max-width: 460px) {
  .writing-hero-background {
    display: none;
  }

  .quotes .grid-item .quote {
    font-size: var(--step-1);
  }

  .quotes .grid-item .quote-title {
    font-size: var(--step-0);
  }

  .quotes .grid-item .quote-title span {
    font-size: var(--step--1);
  }

  .footer .row .flex-col:nth-child(1) {
    align-self: center;
    justify-self: center;
  }

  .footer .row .flex-col:nth-child(1) img {
    width: 100%;
    padding-inline: 20%;
    padding-bottom: 2rem;
  }

  .footer-grid .grid-col:nth-child(2) {
    border-top: 1px solid transparent;
  }
}

.get-a-copy {
  margin-top: var(--flex-padding);
}

.gac-grid {
  display: grid;
  gap: calc(100vw * 0.02 - 4px);
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(1fr);
  padding-bottom: var(--section-padding);
  padding-left: 6rem;
  padding-right: 6rem;
}

.gac-grid .book-cover {
  position: relative;
  grid-column: 5 / 7;
  grid-row: 1 / 3;
  border-radius: 1rem;
}

.hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.clickable-image {
  cursor: pointer;
}

.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 1rem;
  padding: 20px;
  z-index: 1001;
  width: 95%;
}

.gac-grid .book {
  padding-left: 0;
  padding-inline: var(--flex-padding);
  width: 100%;
}


.gac-grid .book-info {
  grid-column: 1 / 5;
  grid-row: 1 / 3;
}

.book-title {
  padding-bottom: var(--paragraph-padding);
}

.book-title h4,
.book-title p {
  margin: 0;
}

.book-title p {
  font-style: italic;
}

.book-mini-sum {
  text-indent: 2em;
  padding-right: 6rem;
  margin-bottom: var(--flex-padding);
}

.purchase-buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.purchase-buttons a {
  font-size: var(--step--2);
}

.purchase-buttons a:last-child {
  margin-right: 0;
}

.publish-info {
  margin-top: var(--flex-padding);
}

.publish-info p {
  font-size: var(--step--1);
  margin-bottom: 0;
  margin-top: 0;
  color: var(--color-light-grey);
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(255, 255, 255);
  transform-origin: left;
  z-index: 3000;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(255, 255, 255);
  transform-origin: right;
  z-index: 3000;
}

.social-card {
  border-top: 1px solid var(--border-primary);
  width: 100%;
  margin-right: var(--flex-padding);
}

.social-card ul li a {
  display: flex;
  align-items: center;
}

.social-card ul li a img {
  width: 3rem;
}

.social-card ul li a h4 {
  padding-left: var(--paragraph-padding);
  margin: 0;
}

.reviews {
  padding-bottom: var(--section-padding);
}

.reviews h2 {
  border-bottom: 1px solid var(--border-primary);
  margin-bottom: var(--section-padding);
}

.reviews-cover-image-container {
  width: 100%;
  height: 50rem;
  overflow: hidden;
}

.reviews-cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-reviews {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
}

.review-title {
  display: flex;
  justify-content: space-between;
  align-items: top;
}

.review-title h5 {
  font-family: 'noto', sans-serif;
  font-weight: 600;
  letter-spacing: -0.025rem;
  text-transform: uppercase;
  margin: 0;
}

.grid-item img {
  width: 100%;
}

.grid-reviews .grid-item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 100%;
  width: 100%;
  height: max-content;
  overflow: hidden;
  background: #f1ebed;
  border-radius: 1rem;
  padding: 2rem;
}

.grid-reviews .grid-item .logo {
  width: 100px;
  margin-bottom: 1rem;
}

.grid-reviews .grid-item .midwest {
  width: 200px;
  margin-bottom: 1rem;
}

.RatingStatistics {
  box-sizing: border-box;
  border-radius: 0.8rem;
  display: inline-flex;
  padding: 0.8rem;
  margin: -0.8rem -0.8rem 0 -0.8rem;
  background-color: rgba(0, 0, 0, 0.05);
}

.RatingStatistics__column {
  display: flex;
  width: 100%;
}

.RatingStars {
  display: inline-flex;
}

.RatingStar__backgroundFill {
  fill: #c2c7cc;
  fill: var(--color-background-rating-star-empty);
  transition: fill .2s;
}

.RatingStar__fill {
  transition: fill .2s;
}

.RatingStar__fill {
  fill: #e87400;
  fill: var(--color-background-rating-star-base);
}

.RatingStars__medium {
  margin-left: -0.6rem;
}

.RatingStar--medium {
  box-sizing: border-box;
  width: 3.25rem;
  height: 3.25rem;
  padding: 0.5rem;
}

.RatingStatistics__rating {
  font-family: Copernicus, "Libre Baskerville", Georgia, serif;
  font-weight: 600;
  font-size: 2rem;
}

.grid.quotes .btn {
  margin-top: 0;
}

.review-popup-box {
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  transition: 0.4s;
  z-index: 1;
  padding: 1.25rem 1.875rem;
  opacity: 0;
}

.review-popup-box:before {
  position: absolute;
  content: "";
  background: var(--color-dark);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.95;
  border-radius: 1rem;
}

.grid-item:hover .review-popup-box {
  bottom: 25px;
  opacity: 1;
}

.review-popup-box div {
  position: relative;
  color: white;
  font-size: 1.5rem;
  line-height: 1.63;
  letter-spacing: 1.63px;
  padding-bottom: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
  pointer-events: none;
}

.review-popup-box h3 {
  pointer-events: none;
  color: white;
  font-size: 1.5rem;
  letter-spacing: -0.5px;
  font-weight: 700;
  margin-bottom: 0;
}

.review-popup-box img {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  max-width: 1.25rem;
}

/* @media (max-width: 768px) {
  .App::before {
    content: '';
    background-color: #252525;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4000;
  }

  .App::after {
    content: 'Mobile experience coming soon...Please open the site on a desktop';
    color: white;
    position: fixed;
    top: 50%;
    z-index: 4001;
  }
} */